var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "footer",
    {
      staticClass: "footer d-print-none bg-dark text-white",
      attrs: { id: "footer" },
    },
    [
      _c("div", { staticClass: "boxed-layout pb-1" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-8" }, [
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v("EFM Versicherungsmakler AG"),
            ]),
            _vm._v(" "),
            _c("p", [
              _vm._v("\n          Berichterstellung Risikoprofil "),
              _c("span", { staticClass: "version" }, [
                _vm._v(_vm._s(_setup.version)),
              ]),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "col text-right" }),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "text-center mt-3" }, [
          _c("small", [
            _vm._v(
              "\n        Copyright ©\n        " +
                _vm._s(_setup.year) +
                "\n      "
            ),
          ]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import http from '@/shared/config/httpService';

import buildPaginationQueryOpts from '@/shared/sort/sorts';

import { ICustomer } from '@/shared/model/customer.model';

const baseApiUrl = 'api/customers';

const efmCustomerApiUrl = 'api/v1/customers';

export default class CustomerService {
  public async find(id: number): Promise<ICustomer> {
    const res = await http.get(`${baseApiUrl}/${id}`);
    return res.data;
  }

  public async findByFremdId(id: number): Promise<ICustomer> {
    const res = await http.get(`${efmCustomerApiUrl}/fremd-id/${id}`);
    return res.data;
  }

  public async retrieve(paginationQuery?: any): Promise<any> {
    return http.get(baseApiUrl + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public async findFiltered(
    searchText: any,
    paginationQuery?: { size: number; page: number; sort: string[] }
  ) {
    return http.get(
      `${baseApiUrl}/filtered?search=${searchText}&${buildPaginationQueryOpts(paginationQuery)}`
    );
  }

  public async retrieveReportDtos(paginationQuery?: any): Promise<any> {
    return http.get(`${baseApiUrl}/report-dtos` + `?${buildPaginationQueryOpts(paginationQuery)}`);
  }

  public async retrieveCoInsured(customerId: number): Promise<ICustomer[]> {
    const res = await http.get(`${baseApiUrl}/${customerId}/co-insured`);
    return res.data;
  }

  public async delete(id: number): Promise<any> {
    return http.delete(`${baseApiUrl}/${id}`);
  }

  public async create(entity: ICustomer): Promise<ICustomer> {
    const res = await http.post(`${baseApiUrl}`, entity);
    return res.data;
  }

  public async update(entity: ICustomer): Promise<ICustomer> {
    const res = await http.put(`${baseApiUrl}/${entity.id}`, entity);
    return res.data;
  }

  /**
   * Search for customers
   * @param {string} searchText
   * @return {Promise<ICustomer[]>}
   */
  public async searchCustomers(searchText: string): Promise<ICustomer[]> {
    const res = await http.get(`${efmCustomerApiUrl}?search=${searchText}`);
    return res.data;
  }

  /**
   * returns related customers for given customer
   * @param {ICustomer} selectedMainCustomer
   * @return {Promise<ICustomer[]>}
   */
  public async getSubCustomers(selectedMainCustomer: ICustomer): Promise<ICustomer[]> {
    const res = await http.get(
      `${efmCustomerApiUrl}/${selectedMainCustomer.fremdId}/sub-customers`
    );
    return res.data;
  }
}

import { render, staticRenderFns } from "./navBarLayout.vue?vue&type=template&id=49db9288&scoped=true&"
import script from "./navBarLayout.vue?vue&type=script&lang=ts&setup=true&"
export * from "./navBarLayout.vue?vue&type=script&lang=ts&setup=true&"
import style0 from "./navBarLayout.vue?vue&type=style&index=0&id=49db9288&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49db9288",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/daniel/Desktop/business/EFM/02 RCP/code/rcp-frontend/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('49db9288')) {
      api.createRecord('49db9288', component.options)
    } else {
      api.reload('49db9288', component.options)
    }
    module.hot.accept("./navBarLayout.vue?vue&type=template&id=49db9288&scoped=true&", function () {
      api.rerender('49db9288', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "app/layouts/navBarLayout.vue"
export default component.exports
import http from '@/shared/config/httpService';
import AuthService from '@/account/auth.service';

const TIMEOUT = 1000000;

/**
 * Konfiguriert die Axios-Interceptoren für Authentifizierung und Fehlerbehandlung
 * @param onUnauthenticated Callback-Funktion für unauthentifizierte Anfragen
 * @param onServerError Callback-Funktion für Serverfehler
 * @param authService Authentifizierungsdienst
 */
const setupAxiosInterceptors = (onUnauthenticated, onServerError, authService: AuthService) => {
  const onResponseError = err => {
    const status = err.status || err.response?.status;
    if (status === 403 || status === 401) {
      console.log('unauthenticated');
      return onUnauthenticated(err);
    }
    if (status >= 500) {
      return onServerError(err);
    }
    return Promise.reject(err);
  };

  if (http.interceptors) {
    // Request-Interceptor für die Authentifizierung mit MSAL
    http.interceptors.request.use(async request => {
      let token = null;
      try {
        // inject token from authService
        await authService.initialize();
        token = await authService.acquireTokenSilent();
      } catch (e) {
        console.error('Axios Request Interceptor: Error while acquiring token', e);
      }
      if (token) {
        if (!request.headers) {
          request.headers = {};
        }
        request.headers.Authorization = `Bearer ${token.accessToken}`;
      }

      request.timeout = TIMEOUT;

      // Stellen Sie sicher, dass SERVER_API_URL definiert ist
      if (typeof SERVER_API_URL !== 'undefined') {
        request.url = `${SERVER_API_URL}${request.url}`;
      } else {
        console.warn('SERVER_API_URL ist nicht definiert. Verwende relative URL.');
      }

      return request;
    });

    // Response-Interceptor für Fehlerbehandlung
    http.interceptors.response.use(res => res, onResponseError);
  }
};

export { setupAxiosInterceptors };

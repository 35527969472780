// src/services/httpService.js
import { BACKEND_URL } from '@/constants';
import axios from 'axios';

/**
 * custom axios instance to handle http requests with custom configurations, such as backend url
 */

console.log('BACKEND_URL', BACKEND_URL);

const http = axios.create({
  baseURL: BACKEND_URL,
  timeout: 1000000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

// Copy default headers from axios
http.defaults.headers = axios.defaults.headers;

export default http;

// src/services/authService.ts
import { AccountInfo, AuthenticationResult, PublicClientApplication } from '@azure/msal-browser';
import { loginRequest, msalConfig } from '@/shared/config/msalConfig';
import http from '@/shared/config/httpService';
import { jwtDecode } from 'jwt-decode';

const BE_ACCESS_TOKEN_KEY = 'rcp-be-access-token';

const RCP_PROFILE_KEY = 'rcp-profile';

export default class AuthService {
  // Expose the instance if needed (e.g. for logout)
  public msalInstance: PublicClientApplication;

  constructor() {
    this.msalInstance = new PublicClientApplication(msalConfig);
    this.initializeOnly().then(() => {
      // Process the redirect response (if using redirect flow)
      this.msalInstance
        .handleRedirectPromise()
        .then(result => {
          if (result) {
            this.storeAccount(result.account);
          }
        })
        .catch(error => console.error('Redirect error:', error));
    });
  }

  //handle redirect

  /**
   * Initialize MSAL and process any redirect response.
   */
  async initialize(): Promise<void> {
    await this.initializeOnly();

    // Process the redirect response.
    try {
      const result: AuthenticationResult | null = await this.msalInstance.handleRedirectPromise();
      if (result) {
        this.storeAccount(result.account);
      }
    } catch (error) {
      console.error('Redirect error:', error);
    }
  }

  async initializeOnly(): Promise<void> {
    // Wait for initialization.
    await this.msalInstance.initialize();
  }

  /**
   * Initiates a login using redirect.
   */
  loginRedirect(): void {
    this.msalInstance.loginRedirect(loginRequest);
  }

  /**
   * Attempts to silently acquire an access token.
   */
  async acquireTokenSilent(): Promise<AuthenticationResult | { accessToken: string }> {
    // logic to allow a headless browser to inject a given token and not use the msal flow
    // check if overruling token is set
    const overrulingToken = this.findOverrulingAccessToken();
    if (overrulingToken) {
      return {
        accessToken: overrulingToken,
      };
    }

    try {
      const accounts = this.msalInstance.getAllAccounts();
      if (accounts.length === 0) {
        throw new Error('No account logged in');
      }
      const silentRequest = {
        ...loginRequest,
        account: accounts[0],
      };
      return await this.msalInstance.acquireTokenSilent(silentRequest);
    } catch (error) {
      console.error('Token acquisition failed:', error);
      throw error;
    }
  }

  /**
   * Logs out using a redirect.
   */
  async logout(): Promise<void> {
    localStorage.removeItem('msalAccount');

    await this.msalInstance.logoutRedirect();
    this.removeProfile();
  }

  /**
   * checks if user is authenticated
   */
  isAuthenticated(): boolean {
    if (this.findOverrulingAccessToken()) {
      console.log('isAuthenticated: overruling token found');
      return true;
    }

    return this.msalInstance.getAllAccounts().length > 0;
  }

  /**
   * Retrieves account information from localStorage.
   */
  getAccount(): AccountInfo | null {
    const account = localStorage.getItem('msalAccount');
    return account ? JSON.parse(account) : null;
  }

  public async findProfile(): Promise<Profile | null> {
    //check if userDetails is already in sessionStore
    const profile = this.loadProfile();
    if (profile) {
      console.debug('findProfile: Found profile in sessionStore');
      return profile;
    }

    // if profile was not found in sessionStore, try to retrieve it from the backend
    try {
      const response = await http.get<any>('api/v2/account');

      console.log(response);
      if (response.status === 200 && response.data) {
        const account = response.data;
        this.storeProfile(account);
        return account;
      }
    } catch (error) {
      console.error('Error retrieving account', error);
    }
    return null;
  }

  public async getCurrentMandantId(): Promise<number> {
    return (await this.findProfile())?.mandantId || 0;
  }

  public async isAdmin(): Promise<boolean> {
    const profile = await this.findProfile();

    if (!profile) {
      return false;
    }
    return profile.authorities.includes('ROLE_ADMIN');
  }

  public findOverrulingAccessToken() {
    const token = localStorage.getItem(BE_ACCESS_TOKEN_KEY);
    if (!token) {
      return null;
    }

    // note: this may be a scuriyt risk
    // note2: make sure that the token is still valid
    if (this.isTokenValid(token)) {
      return token;
    }
    //remove token if it is not valid
    localStorage.removeItem(BE_ACCESS_TOKEN_KEY);
    return null;
  }

  private isTokenValid(token: string): boolean {
    try {
      // Decode the token without verifying the signature
      const decoded = jwtDecode<{
        exp: number;
      }>(token);
      if (!decoded || !decoded.exp) {
        console.warn('isTokenValid: Token does not contain an expiration claim.');
        return false;
      }
      // Check if the token has expired (exp is in seconds)
      if (Date.now() >= decoded.exp * 1000) {
        console.warn('isTokenValid: Overruling access token has expired.');
        return false;
      }
      // Token is valid
      return true;
    } catch (error) {
      console.error('isTokenValid: Invalid JWT token found:', error);
      return false;
    }
  }

  private storeProfile(profile: Profile): void {
    sessionStorage.setItem(RCP_PROFILE_KEY, JSON.stringify(profile));
  }

  private loadProfile(): Profile | null {
    const profile = sessionStorage.getItem(RCP_PROFILE_KEY);
    if (profile) {
      return JSON.parse(profile);
    }
    return null;
  }

  /**
   * Stores account information in localStorage.
   */
  private storeAccount(account: AccountInfo): void {
    localStorage.setItem('msalAccount', JSON.stringify(account));
  }

  private removeProfile() {
    sessionStorage.removeItem(RCP_PROFILE_KEY);
  }
}

export type Profile = {
  authorities: string[];
  mandantId: number;
  oehs: number[];
  userId: number;
  username: string;
};

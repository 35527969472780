// src/msalConfig.ts
import { Configuration, LogLevel } from '@azure/msal-browser';
import { FRONTEND_URL } from '@/constants';

export const msalConfig: Configuration = {
  auth: {
    clientId: '5cb373eb-ba12-4627-ac1a-e0a34327f9e7',
    redirectUri: FRONTEND_URL,
    authority: 'https://login.microsoftonline.com/6b3dee12-a52c-46dc-99e3-7efe9b8a913f',
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) return;
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            break;
          case LogLevel.Info:
            console.info(message);
            break;
          case LogLevel.Verbose:
            console.debug(message);
            break;
          case LogLevel.Warning:
            console.warn(message);
            break;
        }
      },
      logLevel: LogLevel.Info,
    },
  },
};

export const loginRequest = {
  //  scopes: ['User.Read', 'api://066efd25-9a43-4128-ac89-c8ed511f3335/access_as_user'], // Adjust scopes as needed
  scopes: [
    //'api://5cb373eb-ba12-4627-ac1a-e0a34327f9e7/access_as_user', // Risikoprofil - FE
    'api://066efd25-9a43-4128-ac89-c8ed511f3335/access_as_user', //Risikoprofil BACKEND
    //'api://066efd25-9a43-4128-ac89-c8ed511f3335/.default', //Risikoprofil BACKEND -> 2 fenster
  ],
  prompt: 'consent',

  // following scopes are default
  //  'openid',
  //  'profile',
  //  'email',
  //    'api://066efd25-9a43-4128-ac89-c8ed511f3335/access_as_user', //Risikoprofil BACKEND
  //'api://d88e0da7-38c9-4ea2-b394-7edaab61c5e0/efmapi_dev_access', // RE - BACKEND
  //'api://5cb373eb-ba12-4627-ac1a-e0a34327f9e7/access_as_user', // RCP - FE
};

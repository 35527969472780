// Errors
export const PROBLEM_BASE_URL = 'https://www.jhipster.tech/problem';
export const EMAIL_ALREADY_USED_TYPE = `${PROBLEM_BASE_URL}/email-already-used`;
export const LOGIN_ALREADY_USED_TYPE = `${PROBLEM_BASE_URL}/login-already-used`;
export const TOKEN_STORAGE_KEY = 'cdp-gen-authenticationToken';
export const REQUEST_URL_SESSION_STORAGE_KEY = 'requested-url';

export const ITEMS_PER_PAGE = 25;

export const VERDAS_MANDANT_ID = 2;
export const EFM_MANDANT_ID = 1;
export const MANDANT_QUERY_PARAM = 'mandant';
// frontend version
export const FE_VERSION = process.env.FE_VERSION;
// api backend url
export const BACKEND_URL = process.env.BACKEND_URL;
// frontend url
export const FRONTEND_URL = process.env.FRONTEND_URL;

export const FE_PROFILE = process.env.FE_PROFILE;
